import { navigate } from "gatsby";
import {
  Box,
  Card,
  DateInput,
  InfiniteScroll,
  Layer,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TextInput,
} from "grommet";
import { Search, View } from "grommet-icons";
import moment from "moment";
import React from "react";
import { EventEmitter } from "../../components/elements/event_emitter";
import FormField from "../../components/elements/form_field";
import StandardText, { COLOR } from "../../components/elements/standard_text";
import LoadingAnimation from "../../components/loading_animation";
import NavigationFrame from "../../components/nav_frame";
import { searchCatalog } from "../../service/catalog_service";
import { CANCELED, NEW, REJECTED } from "../../service/model_utils";
import { searchOrders } from "../../service/order_service";
import { getPartners } from "../../service/partner_service";
import { getSimpleDateTime, getSimpleDateTimeFR } from "../../service/utils";

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: "short",
  day: "numeric",
});
const isRemarkable = status => status === NEW || status === REJECTED || status === CANCELED;
class Orders extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      data: location.state?.data?.orders || [],
      pagination: location.state?.data?.pagination || {
        page: 1,
        pageCount: 1,
        total: 100,
        pageSize: 100,
      },
      selectedOrder: undefined,
      partners: [],
      catalogs: [],
      selectedPartner: undefined,
      loading: false,
      filters: {
        partner: undefined,
        status: undefined,
        start_date: moment()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss.000Z"),
        end_date: moment().endOf("date").format("YYYY-MM-DDTHH:mm:ss.000Z"),
      },
    };
  }

  componentDidMount = () => {
    if (this.state.data.length === 0) {
      this.initOrders();
    }
    this.onPartnersSearch();
    this.onCatalogsSearch();
  };

  onPartnersSearch = (query) => {
    let timerId = this.state.timerId;
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      getPartners(query).then((res) => {
        this.setState({ partners: res.data.data });
      });
    }, 350);
    this.setState({ timerId });
  };

  onCatalogsSearch = (query) => {
    let timerId = this.state.timerId;
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      searchCatalog({ query }).then((res) => {
        this.setState({ catalogs: res.data.data });
      });
    }, 350);
    this.setState({ timerId });
  };

  initOrders = () => {
    this.setState({ loading: "Loading orders ..." });
    searchOrders({ filters: this.state.filters })
      .then((res) => {
        this.setState({
          data: res.data.data,
          loading: false,
          pagination: res.data.meta.pagination,
        });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  onFilterChange = (field, value) => {
    const filters = this.state.filters;
    filters[field] = value;
    if (!value) filters[field] = undefined;
    this.setState(
      {
        data: [],
        filters: { ...filters },
        pagination: { page: 1, pageSize: 100, total: 100 },
      },
      this.onOrdersSearch
    );
  };

  onOrdersSearch = () => {
    this.setState({ loading: "Searching orders ..." });
    searchOrders({
      filters: this.state.filters,
      page: this.state.pagination.page,
    })
      .then((res) => {
        this.setState({
          data: res.data.data,
          loading: false,
          pagination: res.data.meta.pagination,
        });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  onMore = () => {
    if (this.state.pagination.pageCount <= this.state.pagination.page) return;
    const currentPage = this.state.pagination.page;
    this.setState({ loading: "Searching orders ..." });
    searchOrders({
      filters: this.state.filters,
      page: currentPage + 1,
    })
      .then((res) => {
        this.setState({
          data: [...this.state.data, ...res.data.data],
          loading: false,
          pagination: res.data.meta.pagination,
        });
      })
      .catch((err) => {
        EventEmitter.errorHandler(err);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <NavigationFrame>
        <Box width={"full"} justify="center" align="center">
          <Box
            justify="center"
            width={"full"}
            pad={{ vertical: "small" }}
            direction="row"
            style={{ minHeight: "auto" }}
          >
            <Box direction="row" gap="small">
              <FormField
                label={"Search"}
                size="small"
                component={
                  <TextInput
                    icon={<Search />}
                    size="small"
                    value={this.state.filters._q}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.onFilterChange("_q", e.target.value);
                      }
                    }}
                    onChange={(e) =>
                      this.setState({
                        filters: { ...this.state.filters, _q: e.target.value },
                      })
                    }
                  />
                }
              />
              <FormField
                label={"Partner"}
                size="small"
                component={
                  <Select
                    placeholder="All partners"
                    size="small"
                    labelKey="name"
                    valueKey={{ key: "id", reduce: true }}
                    onSearch={(text) => this.onPartnersSearch(text)}
                    options={this.state.partners}
                    onChange={({ value: nextValue }) => {
                      this.onFilterChange("partner", nextValue);
                    }}
                    multiple={false}
                    clear={{ position: "bottom" }}
                  />
                }
              />
              <FormField
                label={"Catalogs"}
                size="small"
                component={
                  <Select
                    placeholder="All catalogs"
                    size="small"
                    labelKey="name"
                    valueKey={{ key: "id", reduce: true }}
                    onSearch={(text) => this.onCatalogsSearch(text)}
                    options={this.state.catalogs}
                    onChange={({ value: nextValue }) => {
                      this.onFilterChange("catalog", nextValue);
                    }}
                    clear={{ position: "bottom" }}
                    multiple={false}
                  />
                }
              />
              <FormField
                label={"Platform"}
                size="small"
                component={
                  <Select
                    placeholder="All Platforms"
                    size="small"
                    options={["UBER_EAT", "DELIVEROO"]}
                    onChange={({ option }) => {
                      this.onFilterChange("platform", option);
                    }}
                    clear={{ position: "bottom" }}
                  />
                }
              />
              <FormField
                label={"Status"}
                size="small"
                component={
                  <Select
                    placeholder="All status"
                    size="small"
                    options={[
                      "NEW",
                      "ACCEPTED",
                      "COMPLETED",
                      "REJECTED",
                      "CANCELED",
                    ]}
                    multiple={true}
                    onChange={({ value: nextValue }) => this.onFilterChange("status", nextValue)}
                    clear={{ position: "bottom" }}
                  />
                }
              />
              <FormField
                label={"From / To"}
                size="small"
                component={
                  <DateInput
                    size="small"
                    value={[
                      this.state.filters.start_date,
                      this.state.filters.end_date,
                    ]}
                    buttonProps={{
                      label: (
                        <StandardText
                          bold
                          label={`${dateFormat.format(
                            new Date(this.state.filters.start_date)
                          )} - ${dateFormat.format(
                            new Date(this.state.filters.end_date)
                          )}`}
                        />
                      ),
                    }}
                    onChange={(event) => {
                      const nextValue = event.value;
                      const filters = this.state.filters;
                      this.setState(
                        {
                          data: [],
                          pagination: { page: 1, pageSize: 100, total: 100 },
                          filters: {
                            ...filters,
                            start_date: nextValue[0],
                            end_date: nextValue[1],
                          },
                        },
                        this.onOrdersSearch
                      );
                    }}
                  />
                }
              />
            </Box>
          </Box>
          <Card
            width={"xxlarge"}
            style={{ overflowY: "auto", minHeight: "auto", height: "100vh" }}
            justify="start"
            align="start"
            pad={"small"}
          >
            <OrdersView
              orders={this.state.data}
              onOrderSelected={(o) =>
                navigate(`/orders/${o.id}`, {
                  state: {
                    data: {
                      orders: this.state.data,
                      pagination: this.state.pagination,
                    },
                  },
                })
              }
              onMore={() => this.onMore()}
            />
          </Card>
        </Box>
        {this.state.loading && (
          <Layer>
            <LoadingAnimation text={this.state.loading} />
          </Layer>
        )}
      </NavigationFrame>
    );
  }
}

const OrdersView = ({ orders, onOrderSelected, onMore }) => {
  const header = [
    { property: "placed_at", label: "Date" },
    { property: "status", label: "Status" },
    { property: "collection_code", label: "Number" },
    { property: "customer_name", label: "Customer" },
    { property: "platform", label: "Platform" },
    { property: "partner_name", label: "Restaurant" },
    { property: "catalog_name", label: "Catalog" },
  ];

  const data = orders.map((o) => ({
    ...o,
    createdAt: getSimpleDateTimeFR(o.createdAt),
    customer_name: o.customer?.first_name,
  }));

  return (
    <Box
      width={"full"}
      background="#fff"
      style={{ minHeight: "auto" }}
      margin={{ bottom: "small" }}
      justify="center"
      align="center"
    >
      <Table>
        <TableHeader>
          <TableRow>
            {header.map((c) => (
              <TableCell key={c.property} scope="col">
                <StandardText label={c.label} color="#999" bold />
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <InfiniteScroll
            renderMarker={(marker) => (
              <TableRow>
                <TableCell>{marker}</TableCell>
              </TableRow>
            )}
            items={data}
            onMore={() => onMore()}
            step={25}
          >
            {(order, index) => {
              const _bgColor = index % 2 === 0 ? "#fff" : "#f8f8f8";
              return (
                <TableRow key={order.id} style={{ background: _bgColor }}>
                  {header.map((c) => (
                    <TableCell key={c.property}>
                      <Box
                        pad={{ vertical: "xsmall" }}
                        onClick={() => {
                          if (c.property === "catalog_name") {
                            navigate("/catalogs/" + order.catalog_id);
                          } else if (c.property === "partner_name") {
                            navigate("/partners/" + order.partner_id);
                          }
                        }}
                      >
                        {c.property === "status" &&
                        isRemarkable(order.status) ? (
                          <StandardText bold label={order.status} color={COLOR.black}/>
                        ) : (
                          <StandardText
                            label={
                              c.property === "placed_at"
                                ? getSimpleDateTime(order.placed_at)
                                : order[c.property]
                            }
                            color={
                              c.property === "placed_at"
                                ? "brand"
                                : c.property === "partner_name" ||
                                  c.property === "catalog_name"
                                ? COLOR.green
                                : COLOR.black
                            }
                          />
                        )}
                      </Box>
                    </TableCell>
                  ))}
                  <TableCell key={"open"}>
                    <View
                      onClick={() => onOrderSelected(order)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              );
            }}
          </InfiniteScroll>
        </TableBody>
      </Table>
    </Box>
  );
};

export default Orders;
